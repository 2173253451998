body { font-size: 18px; }
.topLogo { margin: 0 auto; display: block; padding: 20px 0 0 0; min-width: 33vw; }
/* Container for all posts */
.WpPostListContainer {
    display: flex;              /* Enables Flexbox */
    justify-content: center;     /* Centers items horizontally */
    align-items: flex-start;     /* Aligns items to the top of the container */
    flex-wrap: wrap;             /* Allows posts to wrap to the next line */
    margin: 0 auto;              /* Centers the container on the page */
    padding: 20px;               /* Adds space inside the container */
    max-width: 1200px;           /* Limits the container width */
}

a, a:link, a:focus { text-decoration: none; color: #8A242D; }
a:visited { color: #3D1710; }


.topLogo {
    display: block;
    margin: 0 auto;
    min-width: 33vw;
    padding: 20px 0 0;
    max-width: 40vw;
}

/* General post styles */
.WpPost {
    flex: 1 1 300px;             /* Sets the width of each post and allows resizing */
    margin: 20px;                /* Adds space between each post */
    background-color: #f9f9f9;   /* Background color for posts */
    padding: 20px;               /* Padding inside each post */
    border-radius: 8px;          /* Rounds the corners of each post */
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.WpPost h2 { text-transform: uppercase; font-weight: 700; color:  #B84430; }


/* First post - full width */
.WpPost:first-child {
    flex: 1 1 100%;              /* Full width of the container */
    margin: 20px 0;              /* Adds top and bottom margin */
}

.wp-image-18 {
    z-index: -1;
    object-fit: cover;
    position: fixed;
    left: 0;
    top: 0;
    opacity: .45;
}

@media screen and (max-width: 786px) {
    .wp-image-18 {
        object-fit: fill;
    }
}

#post-13.WpPost h2 { font-size: 34px; color: #351F1F }

.WpPost:first-child img.wp-image-66 { float: left; max-width: 200px; padding: 0 10px 10px 0; }
img.wp-image-66 .wp-caption-text { display: none; visibility: hidden; }

/* Responsive Image Styling */
.WpPost img {
    max-width: 100%;             /* Ensures the image width never exceeds its container */
    height: auto;                /* Maintains the aspect ratio of the image */
    display: block;              /* Removes any inline spacing around the image */
    margin: 0 auto;              /* Center the image inside the post */
}